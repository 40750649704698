
import ReactMarkdown from 'react-markdown';

import style from './Header.module.css';

import header from '../assets/img/dreistuben_header.jpg';
import logo from '../assets/icons/Logo_Drei_Stuben_sw_white.svg';

const Header = ({ navigation }) => {

  return (
    <div className={style.header} style={{ backgroundImage: `url(${header})`}}>
      <nav className={style.nav}>
        <ReactMarkdown  children={navigation}/>
        <button className={style.newsletter} onClick={() => window.showPopup()}>Newsletter</button>
      </nav>
      <div className={style.logo}>
        <img src={logo} alt="Drei Stuben. Beckenhofstrasse 5, 8006 Zürich" />
      </div>
      <div className={style.language}>
        <a href='/de'>Deutsch</a><a href='/en'>English</a>
      </div>
    </div>
  );
}

export default Header;
